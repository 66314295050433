import React from 'react';
import { useObserver } from 'mobx-react';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import 'react-datepicker/dist/react-datepicker.css';
import { useStores } from '../../utils/use-stores';
import './reports.scss';
import UserDataReport from '../../components/user-data-report/user-data-report';
import RaReport from '../../components/ra-report/ra-report';
import DssiReport from '../../components/dssi-report/dssi-report';
import DssiFollowUpsReport from '../../components/dssi-follow-ups-report/dssi-follow-ups-report';

const Reports = () => {
  const { reportsStore } = useStores();

  return useObserver(() => (
    <div className="reports">
      Reports
      {reportsStore.fetching && <CircularProgress />}
      <section>
        <UserDataReport />
      </section>
      <section>
        <RaReport />
      </section>
      <section>
        <DssiReport />
      </section>
      <section>
        <DssiFollowUpsReport />
      </section>
    </div>
  ));
};

export default Reports;
