import React from 'react';

import { action, observable, runInAction } from 'mobx';
import { toast } from 'react-toastify';

import api from '../utils/api';
import { formatErrorResponse, displayErrorMessages } from '../utils/errors';

const API_PATH = '/admin/adhoc/risk_assessment_results_page';

class RAContentStore {
  @observable contentItems = [];

  @observable questions = [];

  @observable fetching = false;

  @action updateContentItem = (value, index) => {
    this.contentItems[index].value = value;
  };

  @action updateOption = (value, qIndex, oIndex) => {
    const question = this.questions[qIndex];
    question.options[oIndex].advice = value;
  };

  @action setFetching = (fetching) => {
    this.fetching = fetching;
  };

  @action fetchContent = async () => {
    this.setFetching(true);

    try {
      const { data } = await api.get(API_PATH);
      runInAction(() => {
        this.contentItems = data?.content_items; //eslint-disable-line
        this.questions = data?.questions;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setFetching(false);
    }
  };

  @action saveContent = async () => {
    this.setFetching(true);

    try {
      await api.put(API_PATH, {
        content_items: this.contentItems,
        options: this.questions.flatMap(({ options }) => options),
      });
      toast.success('RA results content updated');
    } catch (e) {
      const errorMsgs = formatErrorResponse(e.response);
      const Errors = displayErrorMessages(errorMsgs);
      toast.error(<Errors />);
    } finally {
      this.setFetching(false);
    }
  };
}

const raContentStore = new RAContentStore();
export default raContentStore;
