import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { useStores } from '../../utils/use-stores';
import './dashboard.scss';

const Dashboard = () => {
  const { dashboardStore } = useStores();
  const { stat } = dashboardStore;

  useEffect(() => {
    dashboardStore.fetchDashboard();
  }, [dashboardStore]);

  const signUpDayTrend = stat.new_signups_today - stat.new_signups_yesterday;
  const returningDayTrend = stat.returning_users_today - stat.returning_users_yesterday;
  const refDayTrend = stat.referrals_today - stat.referrals_yesterday;
  const formDayTrend = stat.forms_submitted_today - stat.forms_submitted_yesterday;

  const signUpWeekTrend = stat.new_signups_current_week - stat.new_signups_previous_week;
  const returningWeekTrend = stat.returning_users_current_week - stat.returning_users_previous_week;
  const refWeekTrend = stat.referrals_current_week - stat.referrals_previous_week;
  const formWeekTrend = stat.forms_submitted_current_week - stat.forms_submitted_previous_week;

  return useObserver(() => (
    <div className="dashboard">
      Dashboard
      {dashboardStore.fetching && <CircularProgress />}
      <h2 className="subtitle">
        Today`s Metrics: {moment().format('dddd, MMMM D, YYYY')}
      </h2>
      <div className="infos">
        <div className="item">
          <strong className="num">{stat.new_signups_today || 0}</strong>
          <strong className="txt">
            New Signups
          </strong>
        </div>
        <div className="item">
          <strong className="num">{stat.returning_users_today || 0}</strong>
          <strong className="txt">
            Returning Users
          </strong>
        </div>
        <div className="item">
          <strong className="num">{stat.referrals_today || 0}</strong>
          <strong className="txt">
            Referrals
          </strong>
        </div>
        <div className="item">
          <strong className="num">{stat.forms_submitted_today || 0}</strong>
          <strong className="txt">
            Forms Completed
          </strong>
        </div>
      </div>

      <h2 className="subtitle">
        Today&apos;s Trends: Changes Since Yesterday
      </h2>
      <div className="infos">
        <div className="item">
          <strong className="num">{signUpDayTrend > 0 && '+'}{signUpDayTrend || 0}</strong>
          <strong className="txt">
            New Signups
          </strong>
        </div>
        <div className="item">
          <strong className="num">{returningDayTrend > 0 && '+'}{returningDayTrend || 0}</strong>
          <strong className="txt">
            Returning Users
          </strong>
        </div>
        <div className="item">
          <strong className="num">{refDayTrend > 0 && '+'}{refDayTrend || 0}</strong>
          <strong className="txt">
            Referrals
          </strong>
        </div>
        <div className="item">
          <strong className="num">{formDayTrend > 0 && '+'}{formDayTrend || 0}</strong>
          <strong className="txt">
            Forms Completed
          </strong>
        </div>
      </div>

      <h2 className="subtitle">
        This Week&apos;s Trends: Changes Since {moment().subtract(1, 'week').format('dddd, MMMM D, YYYY')}
      </h2>
      <div className="infos">
        <div className="item">
          <strong className="num">{signUpWeekTrend > 0 && '+'}{signUpWeekTrend || 0}</strong>
          <strong className="txt">
            New Signups
          </strong>
        </div>
        <div className="item">
          <strong className="num">{returningWeekTrend > 0 && '+'}{returningWeekTrend || 0}</strong>
          <strong className="txt">
            Returning Users
          </strong>
        </div>
        <div className="item">
          <strong className="num">{refWeekTrend > 0 && '+'}{refWeekTrend || 0}</strong>
          <strong className="txt">
            Referrals
          </strong>
        </div>
        <div className="item">
          <strong className="num">{formWeekTrend > 0 && '+'}{formWeekTrend || 0}</strong>
          <strong className="txt">
            Forms Completed
          </strong>
        </div>
      </div>
    </div>
  ));
};

export default Dashboard;
