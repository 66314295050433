import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import './data-search.scss';
import { replace } from '../../../utils/history';

const DataSearch = ({ onChangeParams, placeholder = '' }) => {
  const [hasChanged, setHasChanged] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [search, setSearch] = useState(params.get('search') || '');
  const [debouncedSearch] = useDebounce(search, 300);

  useEffect(() => {
    if (hasChanged) onChangeParams(`?${params.toString()}`);
  }, [debouncedSearch, onChangeParams]); // eslint-disable-line

  const onSearch = ({ target }) => {
    const { pathname } = location;
    setSearch(target.value);
    params.set('search', target.value);
    replace(`${pathname}?${params.toString()}`);
    if (!hasChanged) setHasChanged(true);
  };

  return useObserver(() => (
    <div className="data-search">
      <input
        type="text"
        className="input-search"
        placeholder={placeholder}
        onChange={onSearch}
        value={search}
      />
    </div>
  ));
};

export default DataSearch;
