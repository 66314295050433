import {
  action, observable, computed,
} from 'mobx';
import { go } from '../utils/history';

class FormsStore {
  @observable list = [];

  @observable editedForm = null;

  @observable head = [
    { disableOrder: false, id: 'title', label: 'Title' },
    { disableOrder: true, id: 'action', label: '' },
  ];

  @computed get forms() {
    return this.list;
  }

  @computed get form() {
    return this.editedForm;
  }

  @action fetchForms = (params = window.location.search) => {
    console.log('FETCH FORMS', params);
    this.list = [
      {
        id: '1',
        title: 'Title of question',
        description: 'Description of question',
        questions: [{
          id: '1',
          question: 'First question',
          answer: [],
          options: [{
            id: '1',
            text: 'Yes',
          }, {
            id: '2',
            text: 'No',
          }],
          choice: '1',
        }, {
          id: '2',
          question: 'Second question',
          answer: [],
          options: [{
            id: '1',
            text: 'Yes',
          }, {
            id: '2',
            text: 'No',
          }],
          choice: '0',
        }],
      }, {
        id: '2',
        title: 'Title of question',
        description: 'Description of question',
        questions: [{
          id: '1',
          question: 'Second question',
          answer: [],
          options: [{
            id: '1',
            text: 'Yes',
          }, {
            id: '2',
            text: 'No',
          }],
          choice: '1',
        }],
      },
    ]; // TODO: fetch forms
  };

  @action fetchForm = (id) => {
    console.log('FETCH FORM by ID', id);
    const form = this.list.find((u) => u.id === id); // TODO: fetch form by id
    if (!form && id !== 'new') {
      go('/forms');
      return;
    }
    this.editedForm = form;
  };

  @action delete = (form) => {
    console.log(form); // TODO: delete form
  };

  @action newForm = (data) => {
    console.log(data);
    // go('/forms');
  };

  @action updateForm = (data) => {
    console.log(JSON.stringify(data));
    // go('/forms');
  };
}

const formsStore = new FormsStore();
export default formsStore;
