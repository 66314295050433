import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useStores } from '../../utils/use-stores';
import FileUpload from '../../components/common/file-upload/file-upload';
import './content-edit.scss';
import { defaultContent } from '../../stores/contentStore';

const schema = yup.object().strict().shape({
  title: yup.string().trim().required(),
  headline: yup.string().trim().required(),
  description: yup.string().trim().required(),
  cta: yup.string().trim(),
  targetUrl: yup.string().trim().required(),
  nickname: yup.string(),
  slug: yup.string(),
  priority: yup.string().trim().required().matches(/^[0]*[0-9]{1,2}$/gi, 'number from 0 to 99'),
  persistent: yup.boolean(),
  inAlert: yup.boolean(),
  inCarousel: yup.boolean(),
  inRisk: yup.boolean(),
  inHistory: yup.boolean(),
});

const ContentEdit = () => {
  const {
    register, handleSubmit, reset, errors, watch, control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultContent,
  });
  const inPersistent = watch('persistent');
  const inAlert = watch('inAlert');
  const params = useParams();
  const isNew = params.id === 'new';
  const { contentStore } = useStores();

  useEffect(() => {
    if (isNew) {
      contentStore.resetContent();
    } else {
      contentStore.fetchContent(params.id);
    }
  }, [contentStore, params.id, isNew]);

  useEffect(() => {
    if (!isNew) reset(contentStore.content);
  }, [contentStore.content, reset, isNew]);

  const onSubmit = (data) => {
    if (isNew) {
      contentStore.newContent(data);
    } else {
      contentStore.updateContent(data);
    }
  };

  return useObserver(() => (
    <div className="content-edit">
      {contentStore.fetching && <CircularProgress />}
      <h3>{isNew ? 'New' : 'Edit'} Content</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className={classNames({ error: errors.title })}>
          <p>Title</p>
          <input type="text" name="title" ref={register} />
          <p className="msg">{errors.title?.message}</p>
        </label>
        <label className={classNames({ error: errors.headline })}>
          <p>Headline</p>
          <input type="text" name="headline" ref={register} />
          <p className="msg">{errors.headline?.message}</p>
        </label>
        <label className={classNames({ error: errors.description })}>
          <p>Description</p>
          <textarea name="description" ref={register} />
          <p className="msg">{errors.description?.message}</p>
        </label>
        <label className={classNames({ error: errors.cta })}>
          <p>Call to Action</p>
          <input type="text" name="cta" ref={register} />
          <p className="msg">{errors.cta?.message}</p>
        </label>
        <label className={classNames({ error: errors.targetUrl })}>
          <p>Target URL</p>
          <input type="text" name="targetUrl" ref={register} />
          <p className="msg">{errors.targetUrl?.message}</p>
        </label>
        <label className={classNames({ error: errors.nickname })}>
          <p>Nickname</p>
          <input type="text" name="nickname" ref={register} />
          <p className="msg">{errors.nickname?.message}</p>
        </label>
        <label className={classNames({ error: errors.slug })}>
          <p>Slug</p>
          <input type="text" name="slug" ref={register} />
          <p className="msg">{errors.slug?.message}</p>
        </label>
        <label className={classNames({ error: errors.targetUrl })}>
          <p>Icon</p>
          <Controller
            control={control}
            name="icon"
            render={({ onChange, value }) => (
              <Select label="Icon" onChange={onChange} value={value}>
                <MenuItem value="report">Report</MenuItem>
                <MenuItem value="video">Video</MenuItem>
                <MenuItem value="dssi">Dssi</MenuItem>
                <MenuItem value="covid">Covid</MenuItem>
                <MenuItem value="chatbot">Chatbot</MenuItem>
                <MenuItem value="friendventory">Friendventory</MenuItem>
                <MenuItem value="resources">Resources</MenuItem>
                <MenuItem value="comeback">Comeback</MenuItem>
              </Select>
            )}
          />
          <p className="msg">{errors.icon?.message}</p>
        </label>
        <div className="upload-block">
          <p>Visual Alerts</p>
          <div className="row">
            <FileUpload
              file={contentStore.mainImage}
              attach={contentStore.attachFile('attach_image', contentStore.setMainImage)}
              remove={contentStore.removeFile(isNew, contentStore.setMainImage)}
              accept="image/*"
              label="Main Image"
              type="image"
            />
            <FileUpload
              file={contentStore.alertImage}
              attach={contentStore.attachFile('attach_image', contentStore.setAlertImage)}
              remove={contentStore.removeFile(isNew, contentStore.setAlertImage)}
              accept="image/*"
              label="Alert Image"
              type="image"
            />
            <FileUpload
              file={contentStore.video}
              attach={contentStore.attachFile('attach_video', contentStore.setVideo)}
              remove={contentStore.removeFile(isNew, contentStore.setVideo)}
              accept="video/*"
              label="Video"
              type="video"
            />
          </div>
        </div>
        <label className={classNames({ error: errors.priority })}>
          <p>Priority</p>
          <input type="number" name="priority" ref={register} />
          <p className="msg">{errors.priority?.message}</p>
        </label>
        <ul className="chk-list">
          <li>
            <label>
              <input type="checkbox" name="persistent" disabled={inAlert} ref={register} />
              <span className="txt">In persistent</span>
              <p>{errors.persistent?.message}</p>
            </label>
          </li>
          <li>
            <label>
              <input type="checkbox" name="inAlert" disabled={inPersistent} ref={register} />
              <span className="txt">Can appear in alert</span>
              <p>{errors.inAlert?.message}</p>
            </label>
          </li>
          <li>
            <label>
              <input type="checkbox" name="inCarousel" ref={register} />
              <span className="txt">Appears in carousel</span>
              <p>{errors.inCarousel?.message}</p>
            </label>
          </li>
          <li>
            <label>
              <input type="checkbox" name="inRisk" ref={register} />
              <span className="txt">Appears in risk panel</span>
              <p>{errors.inRisk?.message}</p>
            </label>
          </li>
          <li>
            <label>
              <input type="checkbox" name="inHistory" ref={register} />
              <span className="txt">Appears in activity history</span>
              <p>{errors.inHistory?.message}</p>
            </label>
          </li>
        </ul>
        <div className="btns">
          <button type="submit" disabled={contentStore.fetching}>Save</button>
          <Link className="button" to="/content">Cancel</Link>
        </div>
      </form>
    </div>
  ));
};

export default ContentEdit;
