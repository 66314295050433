import React, { useState } from 'react';
import { useObserver } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import './reset-password.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { useStores } from '../../utils/use-stores';

const schema = yup.object().strict().shape({
  email: yup.string().trim().email('email must be valid').required(),
});

const ResetPassword = () => {
  const { authStore } = useStores();
  const [serverError, setServerError] = useState('');
  const [success, setSuccess] = useState('');
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setServerError(null);
    try {
      await authStore.resetPassword(data);
      setSuccess('Please check you email and follow the link');
    } catch (e) {
      setServerError(e.message);
    }
  };

  return useObserver(() => (
    <div className="reset">
      {authStore.fetching && <CircularProgress />}
      <h3>Reset Password</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className={classNames({ error: errors.email })}>
          <p>Email</p>
          <input type="email" name="email" ref={register} />
          <p className="msg">{errors.email?.message}</p>
        </label>
        <p className="msg">{serverError}</p>
        <p className="msg success">{success}</p>
        <button type="submit" disabled={authStore.fetching}>Reset password</button>
        <p>
          <Link to="/login">Login</Link>
        </p>
      </form>
    </div>
  ));
};

export default ResetPassword;
