import {
  action, observable, computed, runInAction,
} from 'mobx';
import api from '../utils/api';

class DashboardStore {
  @observable fetching = false;

  @observable statistics = {};

  @computed get stat() {
    return this.statistics;
  }

  @action fetchDashboard = async () => {
    this.fetching = true;
    try {
      const res = await api.get('/admin/dashboard');
      runInAction(() => {
        this.statistics = res.data;
        this.fetching = false;
      });
    } catch (e) {
      console.log(e);
    }
  };
}

const dashboardStore = new DashboardStore();
export default dashboardStore;
