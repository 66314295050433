import React from 'react';
import { useObserver } from 'mobx-react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Header from '../components/header/header';
import Dashboard from '../pages/dashboard/dashboard';
import Users from '../pages/users/users';
import UsersEdit from '../pages/users-edit/users-edit';
import ContentEdit from '../pages/content-edit/content-edit';
import Content from '../pages/content/content';
import Forms from '../pages/forms/forms';
import FormsEdit from '../pages/forms-edit/forms-edit';
import AdminEdit from '../pages/admin-edit/admin-edit';
import Admins from '../pages/admins/admins';
import Reports from '../pages/reports/reports';
import Zapier from '../pages/zapier/zapier';
import RAResultsContentEdit from '../pages/ra-results-content-edit/ra-results-content-edit';
import authorize from '../utils/authorize';

const AuthorizedLayout = ({ defaultRoute }) => useObserver(() => (
  <div className="main-container">
    <Header />
    <Switch>
      <Route exact path="/" component={authorize(Dashboard, 'superuser')} />
      <Route path="/admins/:id" component={authorize(AdminEdit, 'superuser')} />
      <Route path="/admins" component={authorize(Admins, 'superuser')} />
      <Route path="/users/:id" component={authorize(UsersEdit, 'superuser')} />
      <Route path="/users" component={authorize(Users, 'superuser')} />
      <Route path="/forms/:id" component={authorize(FormsEdit, 'superuser')} />
      <Route path="/forms" component={authorize(Forms, 'superuser')} />
      <Route path="/content/:id" component={authorize(ContentEdit, 'superuser')} />
      <Route path="/content" component={authorize(Content, 'superuser')} />
      <Route path="/forms" component={authorize(Dashboard, 'superuser')} />
      <Route path="/content" component={authorize(Dashboard, 'superuser')} />
      <Route path="/reports" component={authorize(Reports, 'superuser', 'report_viewer')} />
      <Route path="/zapier" component={authorize(Zapier, 'superuser')} />
      <Route path="/ra-results-content" component={authorize(RAResultsContentEdit, 'superuser', 'report_viewer')} />
      <Redirect to={defaultRoute} />
    </Switch>
  </div>
));

export default AuthorizedLayout;
