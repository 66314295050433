import React, { useRef, useState } from 'react';
import { useObserver } from 'mobx-react';
import './file-upload.scss';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

const FileUpload = ({
  file, attach, remove, accept, label, type,
}) => {
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState('');

  const attachFile = async (e) => {
    setUploadError('');
    setLoading(true);
    const res = await attach(e);
    setLoading(false);
    setUploadError(res.error);
    if (res.error && formRef.current) formRef.current.reset();
  };

  return useObserver(() => (
    <div className="file-upload">
      {file.url
        ? (
          <div className="preview">
            {type === 'image' && <img src={file.url} alt="file" />}
            {type === 'video' && <video src={file.url} controls />}
            <button type="button" onClick={remove}>x</button>
          </div>
        ) : (
          <form ref={formRef}>
            <label className="label">
              {loading && <CircularProgress />}
              <input
                name="file"
                type="file"
                onChange={attachFile}
                accept={accept}
              />
              <span className="input-zone" />
            </label>
          </form>
        )}
      <span>{label}</span>
      <p className="msg">{uploadError}</p>
    </div>
  ));
};

export default FileUpload;
