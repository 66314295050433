import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import 'react-datepicker/dist/react-datepicker.css';
import { useStores } from '../../utils/use-stores';
import './zapier.scss';

const Zapier = () => {
  const { zapierStore } = useStores();
  const {
    register, handleSubmit, reset, errors,
  } = useForm();

  useEffect(() => {
    zapierStore.fetchEndpoints();
  }, [zapierStore]);

  useEffect(() => {
    reset(zapierStore.endpoints);
  }, [zapierStore.endpoints, reset]);

  return useObserver(() => (
    <div className="reports">
      {zapierStore.fetching && <CircularProgress />}
      <h3>Values for &ldquo;Endpoints&rdquo; Field</h3>
      <form onSubmit={handleSubmit(zapierStore.updateEndpoints)}>
        <label className={classNames({ error: errors.registration })}>
          <p>Registration</p>
          <input type="text" name="registration" ref={register} />
          <p className="msg">{errors.registration?.message}</p>
        </label>
        <label className={classNames({ error: errors.risk_assessment })}>
          <p>Risk Assessment</p>
          <input type="text" name="risk_assessment" ref={register} />
          <p>{errors.risk_assessment?.message}</p>
        </label>
        <label className={classNames({ error: errors.dssi })}>
          <p>Connections Survey</p>
          <input type="text" name="dssi" ref={register} />
          <p>{errors.dssi?.message}</p>
        </label>
        <label className={classNames({ error: errors.receive_emails_change })}>
          <p>Opt Change</p>
          <input type="text" name="receive_emails_change" ref={register} />
          <p>{errors.receive_emails_change?.message}</p>
        </label>
        <label className={classNames({ error: errors.profile_change })}>
          <p>Profile Change</p>
          <input type="text" name="profile_change" ref={register} />
          <p>{errors.profile_change?.message}</p>
        </label>
        <div className="btns">
          <button type="submit">Save</button>
          <Link className="button" to="/">Back</Link>
        </div>
      </form>
    </div>
  ));
};

export default Zapier;
