import React from 'react';

// TODO: This should be provided by the API, but time does not permit this
// solution. Fix accordingly.
const periods = [
  { value: '202010', text: '2020-09/10', info: '2021-01-15 - 2021-02-14' },
  { value: '202011', text: '2020-11', info: '2021-02-15 - 2021-03-14' },
  { value: '202012', text: '2020-12', info: '2021-03-15 - 2021-04-14' },
  { value: '202101', text: '2021-01', info: '2021-04-15 - 2021-05-14' },
  { value: '202102', text: '2021-02', info: '2021-05-15 - 2021-06-14' },
  { value: '202103', text: '2021-03', info: '2021-06-15 - 2021-07-14' },
  { value: '202104', text: '2021-04', info: '2021-07-15 - 2021-08-14' },
  { value: '202105', text: '2021-05', info: '2021-08-15 - 2021-09-14' },
  { value: '202106', text: '2021-06', info: '2021-09-15 - 2021-10-14' },
  { value: '202107', text: '2021-07', info: '2021-10-15 - 2021-11-14' },
  { value: '202108', text: '2021-08', info: '2021-11-15 - 2021-12-14' },
];

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const PeriodSelect = ({ onChange }) => (
  <select name="period" onChange={onChange}>
    <option>Select a base month</option>
    {
        periods.map(({ value, text, info }) => (
          <option key={value} value={value} data-info={info}>{text}</option>
        ))
      }
  </select>
);

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default PeriodSelect;
