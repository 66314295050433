import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { useFieldArray, useForm } from 'react-hook-form';
import useForceUpdate from 'use-force-update';
import { toJS } from 'mobx';
import { useStores } from '../../utils/use-stores';
import './forms-edit.scss';

const FormsEdit = () => {
  const update = useForceUpdate();
  const params = useParams();
  const isNew = params.id === 'new';
  const { formsStore } = useStores();
  const {
    register, handleSubmit, reset, control, watch, setValue,
  } = useForm();
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'questions',
  });

  const rId = () => Math.random().toString(36).substr(2, 9);

  useEffect(() => {
    formsStore.fetchForm(params.id);
    const form = toJS(formsStore.form) || {
      id: rId(),
      title: '',
      description: '',
      questions: [{
        choice: '1',
        question: '',
        answer: [],
        options: [{
          id: rId(),
          text: '',
        }],
      }],
    };
    reset(form);
  }, [formsStore, reset, params.id]);

  const onSubmit = (data) => {
    if (isNew) {
      formsStore.newForm(data);
    } else {
      formsStore.updateForm(data);
    }
  };

  const addQuestion = () => {
    append({
      id: rId(),
      question: '',
      choice: '1',
      answer: [],
      options: [{
        id: rId(),
        text: '',
      }],
    });
  };

  const removeQuestion = (i) => () => remove(i);

  const addOption = (q) => () => {
    q.options.push({ text: '', id: rId() });
    update();
  };

  const removeOption = (q, i, j) => () => {
    const answer = watch(`questions[${i}].answer`);
    const filtered = answer.filter((a) => +a !== j);
    setValue(`questions[${i}].answer`, filtered);
    setValue(`questions[${i}].answer[0]`, filtered);
    q.options.splice(j, 1);
    update();
  };

  const renderOptions = (q, i) => (
    <>
      <ul>
        {q.options.map((o, j) => {
          const choice = watch(`questions[${i}].choice`);
          return (
            <li key={o.id}>
              <span className="radio">
                <input
                  name={+choice ? `questions[${i}].answer[0]` : `questions[${i}].answer`}
                  value={j}
                  type={+choice ? 'radio' : 'checkbox'}
                  ref={register()}
                />
              </span>
              <input
                name={`questions[${i}].options[${j}].text`}
                defaultValue={o.text}
                ref={register()}
                placeholder="New option"
                type="text"
              />
              {q.options.length > 1 && <button type="button" onClick={removeOption(q, i, j)}>x</button>}
            </li>
          );
        })}
      </ul>
      <div className="btns">
        <button onClick={addOption(q)} type="button">+ Add option</button>
      </div>
    </>
  );

  return useObserver(() => (
    <div className="forms-edit">
      <h3>{isNew ? 'New' : 'Edit'} Form</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <label>
            <span className="ttl">Title</span>
            <input name="" ref={register} type="text" />
          </label>
        </div>
        <label>
          <span className="ttl">Description</span>
          <textarea name="description" ref={register} />
        </label>
        <div>
          <span className="ttl">Questions</span>
          <ul>
            {fields.map((q, i) => (
              <li key={q.id}>
                <div className="row">
                  <input
                    name={`questions[${i}].question`}
                    defaultValue={q.question}
                    ref={register()}
                    placeholder="New question"
                    type="text"
                  />
                  <select
                    name={`questions[${i}].choice`}
                    defaultValue={q.choice}
                    ref={register()}
                  >
                    <option value="1">Single Choice</option>
                    <option value="0">Multiple Choice</option>
                  </select>
                </div>
                <div className="btns">
                  {renderOptions(q, i)}
                  {fields.length > 1 && <button onClick={removeQuestion(i)} type="button">Remove this question</button>}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="btns">
          <button type="button" onClick={addQuestion}>+ Add question</button>
          <button type="submit">Save</button>
          <Link className="button" to="/forms">Cancel</Link>
        </div>
      </form>
    </div>
  ));
};

export default FormsEdit;
