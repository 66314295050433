import axios from 'axios';
import authStore from '../stores/authStore'; // eslint-disable-line

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 60000,
});

api.interceptors.request.use((request) => {
  if (authStore.token) request.headers.Authorization = `Bearer ${authStore.token}`;
  return request;
});

api.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) authStore.logout();
  return Promise.reject(error);
});

export default api;
