import { action, observable, computed } from 'mobx';
import { persist } from 'mobx-persist';
import api from '../utils/api'; // eslint-disable-line
import { go } from '../utils/history';
import roleRoutes from '../utils/roleRoutes';

class AuthStore {
  @persist @observable token = JSON.parse(localStorage.getItem('authStore'))?.token;

  @persist('object') @observable user = null;

  @observable fetching = false;

  @computed get check() {
    return this.token;
  }

  @computed get userDetail() {
    return this.user || JSON.parse(localStorage.getItem('authStore'))?.user || {};
  }

  @action login = async (payload) => {
    this.setFetching(true);
    try {
      const res = await api.post('/admin/session', payload);
      this.setAuth(res?.data);

      const { role } = this.user;
      const route = roleRoutes(role);

      go(route);

      return null;
    } catch (e) {
      return e.response?.data?.error;
    } finally {
      this.setFetching(false);
    }
  };

  @action resetPassword = async (payload) => {
    this.setFetching(true);
    try {
      await api.post('/admin/password/recover', payload);
    } catch (e) {
      throw new Error(e.response?.data?.error);
    } finally {
      this.setFetching(false);
    }
  };

  @action setPassword = async (payload) => {
    this.setFetching(true);
    try {
      await api.post('/admin/password/reset', payload);
    } finally {
      this.setFetching(false);
    }
  };

  @action setFetching = (fetching) => {
    this.fetching = fetching;
  };

  @action setAuth = ({ access, admin }) => {
    this.token = access;
    this.user = admin;
  };

  @action logout = () => {
    this.token = null;
  };
}

const authStore = new AuthStore();
export default authStore;
