import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { useLocation } from 'react-router-dom';
import './data-table.scss';
import { replace } from '../../../utils/history';

const DataTable = ({ children, head, onChangeParams }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const o = params.get('order') || '';
    const ob = params.get('orderby') || '';
    const by = head.find((r) => r.id === ob);
    const initialOrder = o && ['asc', 'desc'].includes(o) ? o : 'desc';
    setOrderBy(by ? by.id : '');
    setOrder(initialOrder);
  }, [setOrderBy, setOrder, head]); // eslint-disable-line

  const replaceHistory = (by, o) => {
    params.set('orderby', by);
    params.set('order', o);
    replace(`${location.pathname}?${params.toString()}`);
    onChangeParams(`?${params.toString()}`);
  };

  const handleRequestSort = ({ id, disableOrder }) => () => {
    if (disableOrder) return;
    const newOrder = order === 'desc' && orderBy === id ? 'asc' : 'desc';
    setOrder(newOrder);
    setOrderBy(id);
    replaceHistory(id, newOrder);
  };

  return useObserver(() => (
    <div className="data-table">
      <Table className="table">
        <TableHead className="thead">
          <TableRow>
            {head.map((row) => (
              <TableCell
                key={row.id}
                align={row.align || 'left'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <TableSortLabel
                  active={order && orderBy === row.id}
                  direction={order}
                  onClick={handleRequestSort(row)}
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className="tbody">
          {children}
        </TableBody>
      </Table>
    </div>
  ));
};

export default DataTable;
