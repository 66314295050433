import React, { useState } from 'react';
import { useObserver } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import './login.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useStores } from '../../utils/use-stores';

const schema = yup.object().strict().shape({
  email: yup.string().trim().email('email must be valid').required(),
  password: yup.string().trim().required(),
});

const Login = () => {
  const { authStore } = useStores();
  const [serverError, setServerError] = useState('');
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const error = await authStore.login(data);
    setServerError(error ? 'Invalid email or password' : '');
  };

  return useObserver(() => (
    <div className="login">
      <h3>Login</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className={classNames({ error: errors.email })}>
          <p>Email</p>
          <input type="email" name="email" ref={register} />
          <p className="msg">{errors.email?.message}</p>
        </label>
        <label className={classNames({ error: errors.password })}>
          <p>Password</p>
          <input name="password" type="password" ref={register} />
          <p className="msg">{errors.password?.message}</p>
        </label>
        <p className="msg">{serverError}</p>
        <button type="submit" disabled={authStore.fetching}>Login</button>
        <p>
          <Link to="/reset-password">Reset password</Link>
        </p>
      </form>
    </div>
  ));
};

export default Login;
