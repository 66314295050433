import React, { useState } from 'react';
import { useObserver } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import './set-password.scss';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import qs from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { useStores } from '../../utils/use-stores';
import { go } from '../../utils/history';
import { formatErrorResponse, displayErrorMessages } from '../../utils/errors';

const schema = yup.object().strict().shape({
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const SetPassword = () => {
  const location = useLocation();
  const { token } = qs.parse(location.search);
  const { authStore } = useStores();
  const [serverError, setServerError] = useState('');
  const [success, setSuccess] = useState('');
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setServerError(null);
    try {
      await authStore.setPassword({ ...data, token });
      setSuccess('Please check you email and follow the link');
      go('/login');
    } catch (e) {
      const errorMsgs = formatErrorResponse({ data: e.response.data?.errors });
      const Errors = displayErrorMessages(errorMsgs);
      toast.error(<Errors />);
    }
  };

  if (!token) go('/login');

  return useObserver(() => (
    <div className="set">
      {authStore.fetching && <CircularProgress />}
      <h3>Set New Password</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className={classNames({ error: errors.password })}>
          <p>Password</p>
          <input type="password" name="password" ref={register} />
          <p className="msg">{errors.password?.message}</p>
        </label>
        <label className={classNames({ error: errors.confirmPassword })}>
          <p>Confirm Password</p>
          <input type="password" name="confirmPassword" ref={register} />
          <p className="msg">{errors.confirmPassword?.message}</p>
        </label>
        <p className="msg">{serverError}</p>
        <p className="msg success">{success}</p>
        <button type="submit" disabled={authStore.fetching}>Set new password</button>
        <p>
          <Link to="/login">Login</Link>
        </p>
      </form>
    </div>
  ));
};

export default SetPassword;
