import { useObserver } from 'mobx-react';
import { useStores } from '../../utils/use-stores';

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Authorize = ({ children, roles = [] }) => {
  const { authStore } = useStores();

  return useObserver(() => {
    const { userDetail } = authStore;

    return roles?.includes(userDetail?.role) ? children : null;
  });
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Authorize;
