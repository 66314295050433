import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './date-range.scss';
import moment from 'moment';

const DateRange = ({ rangeSelected }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showPicker, setShowPicker] = useState(false);

  const onChange = ([start, end]) => {
    setStartDate(start);
    setEndDate(end);
    rangeSelected({ start, end });
    setTimeout(() => setShowPicker(!end), 0);
  };

  const hideCalendar = () => setTimeout(() => setShowPicker(false), 0);

  const onFocus = () => setShowPicker(true);

  const formattedStart = startDate ? moment(startDate).format('MM/DD/yyyy') : 'Start';
  const formattedEnd = endDate ? moment(endDate).format('MM/DD/yyyy') : 'End';

  const inputValue = `${formattedStart} - ${formattedEnd}`;

  return (
    <div className="date-range">
      <input
        type="text"
        readOnly
        value={inputValue}
        onClick={onFocus}
      />
      {showPicker && (
        <>
          <div className="overlay" onClick={hideCalendar} />
          <div className="picker">
            <DatePicker
              // selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DateRange;
