import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import 'mobx-react/batchingForReactDom';
import App from './App';
import { history } from './utils/history';

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>, global.document.getElementById('root'),
);
