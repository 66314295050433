import React from 'react';
import Authorize from '../components/authorize/authorize';

export default function authorize(Component, ...roles) {
  return () => (
    <Authorize roles={roles}>
      <Component />
    </Authorize>
  );
}
