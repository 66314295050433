import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { useStores } from '../../utils/use-stores';

import './ra-results-content-edit.scss';

const RAResultsContentEdit = () => {
  const { raContentStore } = useStores();

  useEffect(() => {
    raContentStore.fetchContent();
  }, [raContentStore]);

  const handleContentChange = ({ target }) => {
    const { dataset, value } = target;
    const { index } = dataset;

    raContentStore.updateContentItem(value, index);
  };

  const handleOptionChange = ({ target }) => {
    const { dataset, value } = target;
    const { qindex, oindex } = dataset;

    raContentStore.updateOption(value, qindex, oindex);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    raContentStore.saveContent();
  };

  return useObserver(() => (
    <section>
      <form onSubmit={handleSubmit}>
        <h3>Page Content</h3>
        <div className="grid grid-columns-2">
          {
            raContentStore.contentItems.map(({ label, value, slug }, index) => (
              <label key={slug}>
                <div>{label}</div>
                <input
                  type="text"
                  data-index={index}
                  value={value}
                  onChange={handleContentChange}
                />
              </label>
            ))
          }
        </div>

        <h3>Assessment Recommendations</h3>
        {
          raContentStore.questions.map(({ options, ...q }, qIndex) => (
            <>
              <h4>{q.text}</h4>
              <ul key={q.id}>
                {
                  options.map(({ text, advice, id }, oIndex) => (
                    <li key={id}>
                      <label>
                        <div>{text}</div>
                        <textarea
                          value={advice}
                          data-qindex={qIndex}
                          data-oindex={oIndex}
                          data-key={id}
                          onChange={handleOptionChange}
                        />
                      </label>
                    </li>
                  ))
                }
              </ul>
            </>
          ))
        }
        <button type="submit">Save</button>
      </form>
    </section>
  ));
};

export default RAResultsContentEdit;
