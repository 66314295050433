import {
  action, computed, observable,
} from 'mobx';

class DialogStore {
  @observable dialogs = {
    // [name]: {
    //   component: null,
    //   open: true,
    // },
  };

  @action openDialog = (dialogName, component) => {
    this.dialogs[dialogName] = { component };
    setTimeout(action(() => {
      this.dialogs[dialogName].open = true;
    }));
  };

  @action closeDialog = (dialogType) => {
    this.dialogs[dialogType].open = false;
  };

  @computed get dialogsList() {
    return Object.keys(this.dialogs).map((key) => ({
      key,
      ...this.dialogs[key],
    }));
  }
}

const dialogStore = new DialogStore();
export default dialogStore;
