import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { useStores } from '../../utils/use-stores';
import './admin-edit.scss';

const schema = yup.object().strict().shape({
  firstName: yup.string().trim().required(),
  lastName: yup.string().trim().required(),
  role: yup.string().trim().required(),
  email: yup.string().trim().email('email must be valid').required(),
  password: yup.string().trim().required(),
});

const AdminEdit = () => {
  const {
    register, handleSubmit, reset, errors,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const params = useParams();
  const isNew = params.id === 'new';
  const { adminsStore } = useStores();

  useEffect(() => {
    if (isNew) {
      adminsStore.resetEditedAdmin();
    } else {
      adminsStore.fetchAdmin(params.id);
    }
  }, [adminsStore, params.id, isNew]);

  useEffect(() => {
    reset(adminsStore.admin);
  }, [adminsStore.admin, reset]);

  const onSubmit = (data) => {
    if (isNew) {
      adminsStore.newAdmin(data);
    } else {
      adminsStore.updateAdmin(data);
    }
  };

  return useObserver(() => (
    <div className="admin-edit">
      {adminsStore.fetching && <CircularProgress />}
      <h3>{isNew ? 'New' : 'Edit'} Admin</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          <span className="ttl">First Name</span>
          <input type="text" name="firstName" ref={register} />
          <p>{errors.firstName?.message}</p>
        </label>
        <label>
          <span className="ttl">Last Name</span>
          <input type="text" name="lastName" ref={register} />
          <p>{errors.lastName?.message}</p>
        </label>
        <label>
          <span className="ttl">Email</span>
          <input type="email" name="email" ref={register} />
          <p>{errors.email?.message}</p>
        </label>
        <label>
          <span className="ttl">Password</span>
          <input name="password" type="password" ref={register} />
          <p>{errors.password?.message}</p>
        </label>
        <label>
          <span className="ttl">Role</span>
          <select name="role" ref={register}>
            <option value="superuser">Superuser</option>
            <option value="report_viewer">Report Viewer</option>
          </select>

          <p>{errors.role?.message}</p>
        </label>
        <div className="btns">
          <button type="submit" disabled={adminsStore.fetching}>Save</button>
          <Link className="button" to="/admins">Cancel</Link>
        </div>
      </form>
    </div>
  ));
};

export default AdminEdit;
