import React from 'react';
import { useObserver } from 'mobx-react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import classNames from 'classnames';
import * as yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import { useStores } from '../../utils/use-stores';
import './user-data-report.scss';
import DateRange from '../common/date-range/date-range';

const schema = yup.object().strict().shape({
  range: yup.object().strict().required().shape({
    start: yup.date().required(),
    end: yup.date().required(),
  }),
});

const UserDataReport = () => {
  const {
    handleSubmit, control, errors,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { reportsStore } = useStores();

  return useObserver(() => (
    <div className="report">
      <h2 className="subtitle">
        User Attributes Report
      </h2>
      <form className="user-data" onSubmit={handleSubmit(reportsStore.fetchUserDataReport)}>
        <label className={classNames({ error: errors.range })}>
          <p>Date range</p>
          <Controller
            control={control}
            name="range"
            render={({ onChange }) => <DateRange rangeSelected={onChange} />}
          />
          <p className="msg">{errors.range?.message}</p>
        </label>
        <button type="submit" disabled={reportsStore.fetching}>Download</button>
      </form>
    </div>
  ));
};

export default UserDataReport;
