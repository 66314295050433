import React, { useState } from 'react';
import { useObserver } from 'mobx-react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import classNames from 'classnames';
import * as yup from 'yup';
import { useStores } from '../../utils/use-stores';
import PeriodSelect from './period-select';
import './dssi-follow-ups-report.scss';

// -----------------------------------------------------
// Setup
// -----------------------------------------------------

const schema = yup.object().strict().shape({
  period: yup.string(),
});

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const DssiFollowUpsReport = () => {
  const [info, setInfo] = useState();

  const {
    handleSubmit, control, errors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { reportsStore } = useStores();

  const handleChange = (onChange) => (evt) => {
    const { target } = evt;
    const { selectedIndex, options } = target;
    setInfo(options[selectedIndex].dataset.info);
    onChange(evt);
  };

  return useObserver(() => (
    <div className="report">
      <h2 className="subtitle">
        DSSI Follow-Ups
      </h2>

      <form className="user-data" onSubmit={handleSubmit(reportsStore.fetchDSSIFollowUpsReport)}>
        <label className={classNames({ error: errors.range })}>
          <p>Base Month</p>
          <Controller
            control={control}
            name="period"
            render={({ onChange }) => <PeriodSelect onChange={handleChange(onChange)} />}
          />
          <p className="msg">{errors.period?.message}</p>
        </label>

        {
          info
          && (

            <label className="info">
              <p>Follow-Up</p>
              <p>{info}</p>
            </label>
          )
        }
        <button type="submit" disabled={reportsStore.fetching}>Download</button>
      </form>
    </div>
  ));
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default DssiFollowUpsReport;
