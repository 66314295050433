import {
  action, observable, computed, runInAction,
} from 'mobx';
import { toast } from 'react-toastify';
import { go } from '../utils/history';
import api from '../utils/api';
import buildGRUDParams from '../utils/params';

const defaultUser = {
  firstName: '',
  lastName: '',
  email: '',
  zip: '',
};

class UsersStore {
  @observable list = [];

  @observable total = 0;

  @observable fetching = false;

  @observable editedUser = null;

  @observable head = [
    { disableOrder: false, id: 'first_name', label: 'Name' },
    { disableOrder: false, id: 'email', label: 'Email' },
    { disableOrder: true, id: 'action', label: '' },
  ];

  @computed get users() {
    return this.list;
  }

  @computed get user() {
    return this.editedUser || defaultUser;
  }

  @action fetchUsers = async (params = window.location.search) => {
    this.setFetching(true);
    try {
      const res = await api.get('/admin/users', {
        params: buildGRUDParams(params),
      });
      runInAction(() => {
        this.list = res?.data?.data.map((c) => ({
          id: c.id,
          firstName: c.first_name,
          lastName: c.last_name,
          email: c.email,
        }));
        this.total = res?.data?.pagination?.total_count; // eslint-disable-line
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setFetching(false);
    }
  };

  @action fetchUser = async (id) => {
    this.setFetching(true);
    try {
      const res = await api.get(`/admin/users/${id}`);
      runInAction(() => {
        this.editedUser = {
          id: res.data.id,
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          email: res.data.email,
        };
      });
    } catch (e) {
      go('/users');
    } finally {
      this.setFetching(false);
    }
  };

  @action delete = async (user) => {
    this.setFetching(true);
    try {
      await api.delete(`/admin/users/${user.id}`);
      this.fetchUsers();
      toast.success('User deleted');
    } catch (e) {
      console.log(e);
      toast.error('Delete user error');
    } finally {
      this.setFetching(false);
    }
  };

  @action newUser = (data) => {
    console.log(data);
    // go('/users');
  };

  @action updateUser = (data) => {
    console.log(data);
    // go('/users');
  };

  @action setFetching = (fetching) => {
    this.fetching = fetching;
  };

  @action resetEditedUser = () => {
    this.editedUser = null;
  }
}

const usersStore = new UsersStore();
export default usersStore;
