import React from 'react';
import { useObserver } from 'mobx-react';
import { Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/style.scss';
import { useStores } from './utils/use-stores';
import AuthorizedLayout from './layouts/AuthorizedLayout';
import UnAuthorizedLayout from './layouts/UnAuthorizedLayout';
import DialogContainer from './components/containers/dialog/dialog-container';
import roleRoutes from './utils/roleRoutes';

const App = () => {
  const { authStore } = useStores();

  return useObserver(() => {
    const { userDetail } = authStore;
    const defaultAuthedRoute = roleRoutes(userDetail?.role);

    return (
      <>
        <Switch>
          {
            authStore.check
              ? <AuthorizedLayout defaultRoute={defaultAuthedRoute} />
              : <UnAuthorizedLayout />
          }
        </Switch>
        <DialogContainer />
        <ToastContainer />
      </>
    );
  });
};

export default App;
