import { configure } from 'mobx';
import React from 'react';
import { create } from 'mobx-persist';
import authStore from './authStore';
import dialogStore from './dialogStore';
import usersStore from './usersStore';
import contentStore from './contentStore';
import formsStore from './formsStore';
import adminsStore from './adminsStore';
import dashboardStore from './dashboardStore';
import reportsStore from './reportsStore';
import zapierStore from './zapierStore';
import raContentStore from './raContentStore';

configure({
  enforceActions: 'observed',
});

const stores = {
  authStore,
  dialogStore,
  usersStore,
  contentStore,
  formsStore,
  adminsStore,
  dashboardStore,
  reportsStore,
  zapierStore,
  raContentStore,
};

create()('authStore', authStore);

export default stores;
export const storesContext = React.createContext(stores);
