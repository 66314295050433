import {
  action, observable,
} from 'mobx';
import { toast } from 'react-toastify';
import moment from 'moment';
import api from '../utils/api';

class ReportsStore {
  @observable fetching = false;

  @action setFetching = (fetching) => {
    this.fetching = fetching;
  };

  @action fetchUserDataReport = async (payload) => {
    this.setFetching(true);
    try {
      const res = await api.get('/admin/users_report', {
        params: {
          from_date: moment(payload.range.start).format('MM-DD-yyyy'),
          to_date: moment(payload.range.end).format('MM-DD-yyyy'),
          show_proxy: payload.proxy,
        },
      });
      const url = res.data?.download_url; // eslint-disable-line
      if (url) window.open(url, '_blank');
    } catch (e) {
      toast.error('Create report error');
      console.log(e);
    } finally {
      this.setFetching(false);
    }
  };

  @action fetchRaReport = async (payload) => {
    this.setFetching(true);
    try {
      const res = await api.get('/admin/risk_assessment_responses', {
        params: {
          from_date: moment(payload.range.start).format('MM-DD-yyyy'),
          to_date: moment(payload.range.end).format('MM-DD-yyyy'),
          filter: payload.filter,
        },
      });
      const url = res.data?.download_url; // eslint-disable-line
      if (url) window.open(url, '_blank');
      console.log(payload);
    } catch (e) {
      toast.error('Create report error');
      console.log(e);
    } finally {
      this.setFetching(false);
    }
  };

  @action fetchDSSIReport = async (payload) => {
    this.setFetching(true);
    try {
      const res = await api.get('/admin/dssi_responses', {
        params: {
          from_date: moment(payload.range.start).format('MM-DD-yyyy'),
          to_date: moment(payload.range.end).format('MM-DD-yyyy'),
          // filter: payload.filter,
        },
      });
      const url = res.data?.download_url; // eslint-disable-line
      if (url) window.open(url, '_blank');
      console.log(payload);
    } catch (e) {
      toast.error('Create report error');
      console.log(e);
    } finally {
      this.setFetching(false);
    }
  };

  @action fetchDSSIFollowUpsReport = async (payload) => {
    this.setFetching(true);
    try {
      const res = await api.get('/admin/dssi_follow_ups', {
        params: {
          period: payload.period,
        },
      });

      const url = res.data?.download_url; // eslint-disable-line

      if (url) {
        window.open(url, '_blank');
      }
    } catch (e) {
      toast.error('Create report error');
      console.log(e);
    } finally {
      this.setFetching(false);
    }
  };

  @action fetchSummaryReport = async (payload) => {
    this.setFetching(true);
    try {
      const res = await api.get('/admin//v1/admin/response_summary_report', {
        params: {
          from_date: moment(payload.range.start).format('MM-DD-yyyy'),
          to_date: moment(payload.range.end).format('MM-DD-yyyy'),
          // filter: payload.filter,
        },
      });
      const url = res.data?.download_url; // eslint-disable-line
      if (url) window.open(url, '_blank');
      console.log(payload);
    } catch (e) {
      toast.error('Create report error');
      console.log(e);
    } finally {
      this.setFetching(false);
    }
  };
}

const reportsStore = new ReportsStore();
export default reportsStore;
