import React from 'react';
import { useObserver } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo192.png';
import { useStores } from '../../utils/use-stores';
import User from '../user/user';
import Authorize from '../authorize/authorize';

import './header.scss';

const Header = () => {
  const { authStore } = useStores();

  return useObserver(() => (
    <div className="header">
      <div>
        <NavLink exact className="logo" to="/">
          <img src={logo} alt="" />
        </NavLink>
        <nav>
          <Authorize roles={['superuser']}>
            <NavLink to="/admins">Admins</NavLink>
          </Authorize>

          <Authorize roles={['superuser']}>
            <NavLink to="/users">Users</NavLink>
          </Authorize>

          <Authorize roles={['superuser']}>
            <NavLink to="/forms">Forms</NavLink>
          </Authorize>

          <Authorize roles={['superuser']}>
            <NavLink to="/content">Content</NavLink>
          </Authorize>

          <Authorize roles={['superuser', 'report_viewer']}>
            <NavLink to="/reports">Reports</NavLink>
          </Authorize>

          <Authorize roles={['superuser']}>
            <NavLink to="/zapier">Zapier</NavLink>
          </Authorize>

          <Authorize roles={['superuser', 'report_viewer']}>
            <NavLink to="/ra-results-content">RA Results Content</NavLink>
          </Authorize>
        </nav>
      </div>
      {!authStore.check ? (
        <div className="actions">
          <button type="button" onClick={authStore.login}>Register</button>
          <button type="button" onClick={authStore.login}>Login</button>
        </div>
      ) : <User />}
    </div>
  ));
};

export default Header;
