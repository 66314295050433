import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import TablePagination from '@material-ui/core/TablePagination';
import './data-pagination.scss';
import { replace } from '../../../utils/history';

const DataPagination = ({ onChangeParams, total = 0, perPage = 10 }) => {
  const [page, setPage] = useState(0);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const limit = params.get('limit') || 10;
    const offset = params.get('offset') || 0;
    const initialPage = Math.floor(offset / limit);
    setPage(initialPage);
  }, [setPage]); // eslint-disable-line

  const replaceHistory = (newPage) => {
    const { pathname } = location;
    const limit = params.get('limit') || 10;
    params.set('limit', limit);
    params.set('offset', limit * newPage);
    replace(`${pathname}?${params.toString()}`);
    onChangeParams(`?${params.toString()}`);
  };

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    replaceHistory(newPage);
  };

  return useObserver(() => (
    <div className="data-pagination">
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page}
        onChangePage={onPageChange}
      />
    </div>
  ));
};

export default DataPagination;
