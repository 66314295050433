import {
  action, observable, computed, runInAction,
} from 'mobx';
import { toast } from 'react-toastify';
import api from '../utils/api';
import { go } from '../utils/history';
import buildGRUDParams from '../utils/params';

export const defaultContent = {
  title: '',
  headline: '',
  description: '',
  cta: '',
  targetUrl: '',
  mainImage: '',
  alertImage: '',
  video: '',
  icon: 'resources',
  priority: '',
  persistent: false,
  inAlert: false,
  inCarousel: false,
  inRisk: false,
  inHistory: false,
};

class ContentStore {
  @observable list = [];

  @observable total = 0;

  @observable fetching = false;

  @observable editedContent = null;

  @observable mainImage = { url: null, data: null };

  @observable alertImage = { url: null, data: null };

  @observable video = { url: null, data: null };

  @observable head = [
    { disableOrder: false, id: 'title', label: 'Title' },
    { disableOrder: true, id: 'action', label: '' },
  ];

  @computed get contents() {
    return this.list;
  }

  @computed get content() {
    return this.editedContent || defaultContent;
  }

  @action fetchContents = async (params = window.location.search) => {
    this.setFetching(true);
    try {
      const res = await api.get('/admin/content', {
        params: buildGRUDParams(params),
      });
      runInAction(() => {
        this.list = res?.data?.data.map((c) => ({
          id: c.id,
          title: c.title,
        }));
        this.total = res?.data?.pagination?.total_count; // eslint-disable-line
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setFetching(false);
    }
  };

  @action fetchContent = async (id) => {
    this.setFetching(true);
    try {
      const res = await api.get(`/admin/content/${id}`);
      runInAction(() => {
        this.editedContent = {
          id: res.data.id,
          title: res.data.title,
          headline: res.data.headline,
          description: res.data.description,
          cta: res.data.call_to_action,
          targetUrl: res.data.call_to_action_target,
          nickname: res.data.nickname,
          slug: res.data.slug,
          priority: res.data.priority.toString() || '',
          icon: res.data.icon_name || 'resources',
          persistent: res.data.is_persistent,
          inAlert: res.data.is_in_alert_panel,
          inCarousel: res.data.is_in_carousel,
          inRisk: res.data.is_in_risk_panel,
          inHistory: res.data.is_in_activity_history,
        };
        this.mainImage = {
          url: res.data.main_image_url,
          data: null,
        };
        this.alertImage = {
          url: res.data.alert_image_url,
          data: null,
        };
        this.video = {
          url: res.data.video_url,
          data: null,
        };
      });
    } catch (e) {
      go('/content');
    } finally {
      this.setFetching(false);
    }
  };

  @action delete = async (content) => {
    this.setFetching(true);
    try {
      await api.delete(`/admin/content/${content.id}`);
      this.fetchContents();
      toast.success('Content deleted');
    } catch (e) {
      console.log(e);
      toast.error('Delete content error');
    } finally {
      this.setFetching(false);
    }
  };

  @action newContent = async (data) => {
    this.setFetching(true);
    try {
      await api.post('/admin/content', this.buildPayload(data));
      go('/content');
      toast.success('Content created');
    } catch (e) {
      toast.error('Create content error');
      console.log(e);
    } finally {
      this.setFetching(false);
    }
  };

  @action updateContent = async (data) => {
    this.setFetching(true);
    try {
      await api.patch(`/admin/content/${data.id}`, this.buildPayload(data));
      go('/content');
      toast.success('Content updated');
    } catch (e) {
      toast.error('Update content error');
      console.log(e);
    } finally {
      this.setFetching(false);
    }
  };

  @action setFetching = (fetching) => {
    this.fetching = fetching;
  };

  @action resetContent = () => {
    this.editedContent = null;
    this.mainImage = { url: null, data: null };
    this.alertImage = { url: null, data: null };
    this.video = { url: null, data: null };
  };

  @action attachFile = (link, setter) => async (e) => {
    try {
      const fd = new FormData();
      fd.append('file', e.target.files[0]);
      const res = await api.post(`/admin/content/${link}`, fd, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setter(res.data);
      return {};
    } catch (err) {
      console.log(err);
      return {
        error: 'Upload error',
      };
    }
  };

  @action removeFile = (isNew, setter) => () => {
    setter({
      url: null,
      data: isNew ? null : { delete: true },
    });
  };

  @action setMainImage = (file) => {
    this.mainImage = file;
  };

  @action setAlertImage = (file) => {
    this.alertImage = file;
  };

  @action setVideo = (file) => {
    this.video = file;
  };

  buildPayload = (data) => ({
    title: data.title,
    headline: data.headline,
    description: data.description,
    call_to_action: data.cta,
    call_to_action_target: data.targetUrl,
    nickname: data.nickname,
    slug: data.slug,
    priority: data.priority,
    icon_name: data.icon,
    is_persistent: data.persistent,
    is_in_alert_panel: data.inAlert,
    is_in_risk_panel: data.inRisk,
    is_in_carousel: data.inCarousel,
    is_in_activity_history: data.inHistory,
    main_image_attachment: this.mainImage.data,
    alert_image_attachment: this.alertImage.data,
    video_attachment: this.video.data,
  });
}

const contentStore = new ContentStore();
export default contentStore;
