import React from 'react';
import { useObserver } from 'mobx-react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from '../pages/login/login';
import ResetPassword from '../pages/reset-password/reset-password';
import SetPassword from '../pages/set-password/set-password';

const UnAuthorizedLayout = () => useObserver(() => (
  <div className="main-container">
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/set-password" component={SetPassword} />
      <Redirect to="/login" />
    </Switch>
  </div>
));

export default UnAuthorizedLayout;
