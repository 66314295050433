import React from 'react';

export function formatErrorResponse(response) {
  const { data: errors } = response || {};

  return Object.entries(errors).flatMap(([field, messages]) => (
    messages.map((msg) => `${field}: ${msg}`)
  ));
}

export function displayErrorMessages(messages) {
  return () => (
    <ul>
      {
        messages.map((msg) => (
          <li key={msg}>{msg}</li>
        ))
      }
    </ul>
  );
}
