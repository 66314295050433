import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import DataTable from '../../components/common/data-table/data-table';
import DataSearch from '../../components/common/data-search/data-search';
import DataPagination from '../../components/common/data-pagination/data-pagination';
import { useStores } from '../../utils/use-stores';
import ConfirmDialog from '../../components/common/confirm-dialog/confirm-dialog';
import './admins.scss';

const Admins = () => {
  const { adminsStore, dialogStore } = useStores();

  useEffect(() => {
    adminsStore.fetchAdmins();
  }, [adminsStore]);

  const onConfirm = (row) => () => adminsStore.delete(row);

  const handleDelete = (row) => () => {
    const options = {
      title: 'Delete Admin',
      question: 'Are you sure you want to delete the admin:',
      detail: row.title,
    };
    dialogStore.openDialog('confirm', <ConfirmDialog
      options={options}
      onConfirm={onConfirm(row)}
    />);
  };

  return useObserver(() => (
    <div className="admins">
      {adminsStore.fetching && <CircularProgress />}
      <div className="actions">
        <Link to="/admins/new">Add Admin</Link>
        <DataSearch onChangeParams={adminsStore.fetchAdmins} />
      </div>
      <DataPagination onChangeParams={adminsStore.fetchAdmins} total={adminsStore.total} />
      <DataTable head={adminsStore.head} onChangeParams={adminsStore.fetchAdmins}>
        {adminsStore.admins.map((row) => (
          <TableRow key={row.id}>
            <TableCell align="left">
              <Link to={`/admins/${row.id}`}>{`${row.firstName} ${row.lastName}`}</Link>
            </TableCell>
            <TableCell align="left">{row.email}</TableCell>
            <TableCell align="left">{row.role}</TableCell>
            <TableCell align="right">
              <div className="btn-wrapper">
                <button type="button" onClick={handleDelete(row)}>X</button>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </DataTable>
    </div>
  ));
};

export default Admins;
