import {
  action, observable, computed, runInAction,
} from 'mobx';
import { toast } from 'react-toastify';
import api from '../utils/api';

class ZapierStore {
  @observable points = {};

  @observable fetching = false;

  @computed get endpoints() {
    return this.points;
  }

  @action fetchEndpoints = async () => {
    this.setFetching(true);
    try {
      const res = await api.get('/admin/zapier_settings');
      runInAction(() => {
        this.points = res?.data?.data?.endpoints;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setFetching(false);
    }
  };

  @action updateEndpoints = async (data) => {
    this.setFetching(true);
    try {
      await api.patch('/admin/zapier_settings', {
        settings: { endpoints: data },
      });
      toast.success('Zapier endpoints updated');
    } catch (e) {
      console.log(e);
      toast.error('Zapier endpoints update error');
    } finally {
      this.setFetching(false);
    }
  };

  @action setFetching = (fetching) => {
    this.fetching = fetching;
  };
}

const zapierStore = new ZapierStore();
export default zapierStore;
