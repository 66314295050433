import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useObserver } from 'mobx-react';
import classNames from 'classnames';
import { useStores } from '../../../utils/use-stores';
import './dialog-container.scss';

const DialogContainer = ({ className }) => {
  const { dialogStore } = useStores();

  const dialogClasses = classNames({
    'dialog-window': true,
    [className]: className,
  });

  const close = (key) => () => {
    dialogStore.closeDialog(key);
  };

  return useObserver(() => (
    dialogStore.dialogsList.map((dialog) => (
      <CSSTransition
        key={dialog.key}
        unmountOnExit
        in={dialog.open}
        classNames="dialog"
        timeout={200}
      >
        <div className={dialogClasses}>
          <div className="dialog-overlay" onClick={close(dialog.key)} />
          <div className="scroll-area">
            <div className="indent-area">
              <div className="inner-area">
                <span onClick={close(dialog.key)} className="close" />
                {dialog.component}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    ))
  ));
};

export default DialogContainer;
