import React from 'react';

import {
  action, observable, computed, runInAction,
} from 'mobx';
import { toast } from 'react-toastify';
import api from '../utils/api';
import { go } from '../utils/history';
import { formatErrorResponse, displayErrorMessages } from '../utils/errors';
import buildGRUDParams from '../utils/params';

const defaultAdmin = {
  firstName: '',
  lastName: '',
  email: '',
  role: 'superuser',
};

class AdminsStore {
  @observable list = [];

  @observable total = 0;

  @observable fetching = false;

  @observable editedAdmin = null;

  @observable head = [
    { disableOrder: false, id: 'first_name', label: 'Name' },
    { disableOrder: false, id: 'email', label: 'Email' },
    { disableOrder: false, id: 'role', label: 'Role' },
    { disableOrder: true, id: 'action', label: '' },
  ];

  @computed get admins() {
    return this.list;
  }

  @computed get admin() {
    return this.editedAdmin || defaultAdmin;
  }

  @action fetchAdmins = async (params = window.location.search) => {
    this.setFetching(true);
    try {
      const res = await api.get('/admin/admins', {
        params: buildGRUDParams(params),
      });
      runInAction(() => {
        this.list = res?.data?.data.map((c) => ({
          id: c.id,
          firstName: c.first_name,
          lastName: c.last_name,
          email: c.email,
          role: c.role,
        }));
        this.total = res?.data?.pagination?.total_count; // eslint-disable-line
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setFetching(false);
    }
  };

  @action fetchAdmin = async (id) => {
    this.setFetching(true);
    try {
      const res = await api.get(`/admin/admins/${id}`);
      console.log(res);
      runInAction(() => {
        this.editedAdmin = {
          id: res.data.id,
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          email: res.data.email,
          role: res.data.role,
        };
      });
    } catch (e) {
      go('/admins');
    } finally {
      this.setFetching(false);
    }
  };

  @action delete = async (admin) => {
    this.setFetching(true);
    try {
      await api.delete(`/admin/admins/${admin.id}`);
      this.fetchAdmins();
      toast.success('Admin deleted');
    } catch (e) {
      console.log(e);
      toast.error('Delete admin error');
    } finally {
      this.setFetching(false);
    }
  };

  @action newAdmin = async (data) => {
    this.setFetching(true);
    try {
      await api.post('/admin/admins', {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        password: data.password,
        role: data.role,
      });
      go('/admins');
      toast.success('Admin created');
    } catch (e) {
      const errorMsgs = formatErrorResponse(e.response);

      // NOTE: by virtue of displaying error messages in the toast component, from
      // within this store, we're co-mingling multiple concerns where we really
      // should not be. Rather than refactoring out the display concern, we're
      // doubling down on this questionable practice by now formatting the
      // messages with React.  This approach was taken for the reason that this
      // codebase is temporary, and our budget does not allow us to fix our
      // previously committed sins.
      const Errors = displayErrorMessages(errorMsgs);
      toast.error(<Errors />);
    } finally {
      this.setFetching(false);
    }
  };

  @action updateAdmin = async (data) => {
    this.setFetching(true);
    try {
      await api.patch(`/admin/admins/${data.id}`, {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        password: data.password,
        role: data.role,
      });
      go('/admins');
      toast.success('Admin updated');
    } catch (e) {
      const errorMsgs = formatErrorResponse(e.response);
      const Errors = displayErrorMessages(errorMsgs);
      toast.error(<Errors />);
    } finally {
      this.setFetching(false);
    }
  };

  @action setFetching = (fetching) => {
    this.fetching = fetching;
  };

  @action resetEditedAdmin = () => {
    this.editedAdmin = null;
  }
}

const adminsStore = new AdminsStore();
export default adminsStore;
