import React from 'react';
import { useObserver } from 'mobx-react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import logo from '../../assets/images/logo192.png';
import './user.scss';
import { useStores } from '../../utils/use-stores';

const User = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { authStore } = useStores();

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    authStore.logout();
    closeMenu();
  };

  return useObserver(() => (
    <>
      <button type="button" onClick={openMenu}>
        <span>{authStore.userDetail.first_name} {authStore.userDetail.last_name}</span>
        <Avatar alt="John Doe" src={logo} />
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  ));
};

export default User;
