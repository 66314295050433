import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import DataTable from '../../components/common/data-table/data-table';
import DataSearch from '../../components/common/data-search/data-search';
import DataPagination from '../../components/common/data-pagination/data-pagination';
import { useStores } from '../../utils/use-stores';
import ConfirmDialog from '../../components/common/confirm-dialog/confirm-dialog';
import './content.scss';

const Content = () => {
  const { contentStore, dialogStore } = useStores();

  useEffect(() => {
    contentStore.fetchContents();
  }, [contentStore]);

  const onConfirm = (row) => () => contentStore.delete(row);

  const handleDelete = (row) => () => {
    const options = {
      title: 'Delete Content',
      question: 'Are you sure you want to delete the content:',
      detail: row.title,
    };
    dialogStore.openDialog('confirm', <ConfirmDialog
      options={options}
      onConfirm={onConfirm(row)}
    />);
  };

  return useObserver(() => (
    <div className="content">
      {contentStore.fetching && <CircularProgress />}
      <div className="actions">
        <Link to="/content/new">Add Content</Link>
        <DataSearch onChangeParams={contentStore.fetchContents} />
      </div>
      <DataPagination onChangeParams={contentStore.fetchContents} total={contentStore.total} />
      <DataTable head={contentStore.head} onChangeParams={contentStore.fetchContents}>
        {contentStore.contents.map((row) => (
          <TableRow key={row.id}>
            <TableCell align="left">
              <Link to={`/content/${row.id}`}>{row.title}</Link>
            </TableCell>
            <TableCell align="right">
              <div className="btn-wrapper">
                <button type="button" onClick={handleDelete(row)}>X</button>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </DataTable>
    </div>
  ));
};

export default Content;
