import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { useForm } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useStores } from '../../utils/use-stores';
import './users-edit.scss';

const schema = yup.object().strict().shape({
  firstName: yup.string().trim().required(),
  lastName: yup.string().trim().required(),
  email: yup.string().trim().email('email must be valid').required(),
  zip: yup.string().trim().required(),
});

const UsersEdit = () => {
  const {
    register, handleSubmit, reset, errors,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const params = useParams();
  const isNew = params.id === 'new';
  const { usersStore } = useStores();

  useEffect(() => {
    if (isNew) {
      usersStore.resetEditedUser();
    } else {
      usersStore.fetchUser(params.id);
    }
  }, [usersStore, params.id, isNew]);

  useEffect(() => {
    reset(usersStore.user);
  }, [usersStore.user, reset]);

  const onSubmit = (data) => {
    console.log(data);
    // if (isNew) {
    //   usersStore.newUser(data);
    // } else {
    //   usersStore.updateUser(data);
    // }
  };

  return useObserver(() => (
    <div className="users-edit">
      {usersStore.fetching && <CircularProgress />}
      <h3>{isNew ? 'New' : 'View'} User</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          <p>First Name</p>
          <input type="text" name="firstName" readOnly ref={register} />
          <p>{errors.firstName?.message}</p>
        </label>
        <label>
          <p>Last Name</p>
          <input type="text" name="lastName" readOnly ref={register} />
          <p>{errors.lastName?.message}</p>
        </label>
        <label>
          <p>Email Address</p>
          <input type="email" name="email" readOnly ref={register} />
          <p>{errors.email?.message}</p>
        </label>
        <label>
          <p>Zip Code</p>
          <input type="text" name="zip" readOnly ref={register} />
          <p>{errors.zip?.message}</p>
        </label>
        {/* <button type="submit">Save</button> */}
        <div className="btns">
          <Link className="button" to="/users">Back</Link>
        </div>
      </form>
    </div>
  ));
};

export default UsersEdit;
