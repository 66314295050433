import React from 'react';
import { useObserver } from 'mobx-react';
import './confirm-dialog.scss';
import { useStores } from '../../../utils/use-stores';

const ConfirmDialog = ({ onConfirm, options }) => {
  const { dialogStore } = useStores();

  const handleClose = () => dialogStore.closeDialog('confirm');

  const confirm = () => {
    handleClose();
    onConfirm();
  };

  return useObserver(() => (
    <div className="confirm-dialog-body">
      <h2>{options.title}</h2>
      <p>{options.question}</p>
      <p>{options.detail}</p>
      <button onClick={confirm} type="button">ОК</button>
      <button onClick={handleClose} type="button">Cancel</button>
    </div>
  ));
};

export default ConfirmDialog;
