import qs from 'query-string';

const buildGRUDParams = (s = window.location.search) => {
  const p = qs.parse(s);
  const params = {
    page: ((p.offset || 0) / (p.limit || 10)) + 1,
    per_page: 10,
    order_by: p.orderby,
    direction: p.order,
  };
  if (p.search) params.query = p.search;
  return params;
};

export default buildGRUDParams;
